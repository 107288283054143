import { createSlice } from "@reduxjs/toolkit";

const feedbacks_slice = createSlice({
  name: "feedbacks",
  initialState: {
    feedbacks: {},
  },
  reducers: {
    addFeedbacksToStore: (state, action) => {
      state.feedbacks = action.payload;
    },
    addOneFeedbackToStore: (state, action) => {
      state.feedbacks.unshift(action.payload);
    },
    addFeedbackDetailsToStore: (state, action) => {
      state.feedbacks = action.payload;
    },
    updateFeedbackInStore: (state, action) => {
      const feedbackIndex = state.feedbacks.findIndex(
        (feedback) => feedback.id === action.payload.id
      );

      state.feedbacks[feedbackIndex] = action.payload;
    },
    removeFeedbackFromStore: (state, action) => {
      state.feedbacks = state.feedbacks.filter(
        (feedback) => feedback.id !== action.payload.id
      );
    },
  },
});

export const {
  addFeedbackDetailsToStore,
  addOneFeedbackToStore,
  addFeedbacksToStore,
  updateFeedbackInStore,
  removeFeedbackFromStore,
} = feedbacks_slice.actions;

export default feedbacks_slice.reducer;
