import { createSlice } from "@reduxjs/toolkit";

const gradeLevel_slice = createSlice({
  name: "gradeLevels",
  initialState: {
    gradeLevels: [],
  },
  reducers: {
    addGradeLevelsToStore: (state, action) => {
      state.gradeLevels = action.payload;
    },
    addOneGradeLevelToStore: (state, action) => {
      state.gradeLevels.unshift(action.payload);
    },
    editOneGradeLevelInStore: (state, action) => {
      state.gradeLevels = state.gradeLevels.map((gradeLevel) =>
        gradeLevel.id === action.payload.id ? action.payload : gradeLevel
      );
    },
    removeOneGradeLevelFromStore: (state, action) => {
      state.gradeLevels = state.gradeLevels.filter(
        (gradeLevel) => gradeLevel.id !== action.payload.id
      );
    },
  },
});

export const {
  addGradeLevelsToStore,
  addOneGradeLevelToStore,
  editOneGradeLevelInStore,
  removeOneGradeLevelFromStore,
} = gradeLevel_slice.actions;

export default gradeLevel_slice.reducer;
