import { createSlice } from "@reduxjs/toolkit";

const positions_slice = createSlice({
  name: "positions",
  initialState: {
    positions: {},
  },
  reducers: {
    addPositionsToStore: (state, action) => {
      state.positions = action.payload;
    },
    addNewPosition: (state, action) => {
      state.positions.unshift(action.payload);
    },
    editPosition: (state, action) => {
      state.positions = state.positions.map((position) =>
        position.id === action.payload.id ? action.payload : position
      );
    },
    deletePosition: (state, action) => {
      state.positions = state.positions.filter(
        (position) => position.id !== action.payload.id
      );
    },
  },
});

export const {
  addPositionsToStore,
  addNewPosition,
  editPosition,
  deletePosition,
} = positions_slice.actions;

export default positions_slice.reducer;
