import { createSlice } from "@reduxjs/toolkit";

const employeesSlice = createSlice({
  name: "employees",
  initialState: {
    employees: [],
    employee: {},
    next_of_kins: [],
    educations: [],
    experiences: [],
    documents: [],
    certificates: [],
    socials: [],
  },
  reducers: {
    addEmployeesToStore: (state, action) => {
      state.employees = action.payload;
    },
    addEmployeeDetailsToStore: (state, action) => {
      state.employee = action.payload;
    },
    addOneEmployeeToStore: (state, action) => {
      state.employees.unshift(action.payload);
    },
    updateEmployeeInfo: (state, action) => {
      state.employee = action.payload;
    },
    addNextOfKinsToStore: (state, action) => {
      state.next_of_kins = action.payload;
    },
    deleteNextOfKin: (state, action) => {
      state.next_of_kins = state.next_of_kins.filter(
        (next_of_kin) => next_of_kin.id !== action.payload.id
      );
    },
    addOneKinToStore: (state, action) => {
      state.next_of_kins.unshift(action.payload);
    },
    updateKinInStore: (state, action) => {
      state.next_of_kins = state.next_of_kins.map((next_of_kin) =>
        next_of_kin.id === action.payload.id ? action.payload : next_of_kin
      );
    },
    addEducationDetailsToStore: (state, action) => {
      state.educations = action.payload;
    },
    addOneEducationDetailToStore: (state, action) => {
      state.educations.unshift(action.payload);
    },
    updateEducationDetailInStore: (state, action) => {
      state.educations = state.educations.map((education) =>
        education.id === action.payload.id ? action.payload : education
      );
    },
    deleteEducationDetail: (state, action) => {
      state.educations = state.educations.filter(
        (education) => education.id !== action.payload.id
      );
    },
    addExperiencesToStore: (state, action) => {
      state.experiences = action.payload;
    },
    removeOneExperienceFromStore: (state, action) => {
      state.experiences = state.experiences.filter(
        (experience) => experience.id !== action.payload.id
      );
    },
    addOneExperienceToStore: (state, action) => {
      state.experiences.unshift(action.payload);
    },
    updateExperienceInStore: (state, action) => {
      state.experiences = state.experiences.map((experience) =>
        experience.id === action.payload.id ? action.payload : experience
      );
    },
    addDocumentsToStore: (state, action) => {
      state.documents = action.payload;
    },
    removeOneDocumentFromStore: (state, action) => {
      state.documents = state.documents.filter(
        (document) => document.id !== action.payload.id
      );
    },
    addOneDocumentToStore: (state, action) => {
      state.documents.unshift(action.payload);
    },
    addCertificatesToStore: (state, action) => {
      state.certificates = action.payload;
    },
    addOneCertificateToStore: (state, action) => {
      state.certificates.unshift(action.payload);
    },
    removeOneCertificateFromStore: (state, action) => {
      state.certificates = state.certificates.filter(
        (document) => document.id !== action.payload.id
      );
    },
    addSocialToStore: (state, action) => {
      state.socials = action.payload;
    },
    removeOneSocialFromStore: (state, action) => {
      state.socials = state.socials.filter(
        (social) => social.id !== action.payload.id
      );
    },
    addOneSocialToStore: (state, action) => {
      state.socials.unshift(action.payload);
    },
    updateSocialInStore: (state, action) => {
      state.socials = state.socials.map((social) =>
        social.id === action.payload.id ? action.payload : social
      );
    },
  },
});

export const {
  addEmployeesToStore,
  deleteNextOfKin,
  addOneKinToStore,
  addOneEmployeeToStore,
  updateEmployeeInfo,
  addNextOfKinsToStore,
  addEmployeeDetailsToStore,
  updateKinInStore,
  addEducationDetailsToStore,
  addOneEducationDetailToStore,
  updateEducationDetailInStore,
  deleteEducationDetail,
  addExperiencesToStore,
  removeOneExperienceFromStore,
  addOneExperienceToStore,
  updateExperienceInStore,
  addDocumentsToStore,
  addOneDocumentToStore,
  removeOneDocumentFromStore,
  addCertificatesToStore,
  addOneCertificateToStore,
  removeOneCertificateFromStore,
  addSocialToStore,
  removeOneSocialFromStore,
  addOneSocialToStore,
  updateSocialInStore,
} = employeesSlice.actions;
export default employeesSlice.reducer;
