import { createSlice } from "@reduxjs/toolkit";

const exit_reasons_slice = createSlice({
  name: "exit_reasons",
  initialState: {
    exit_reasons: [],
  },
  reducers: {
    addExitReasonsToStore: (state, action) => {
      state.exit_reasons = action.payload;
    },
  },
});

export const { addExitReasonsToStore } = exit_reasons_slice.actions;

export default exit_reasons_slice.reducer;
