import { createSlice } from "@reduxjs/toolkit";

const incidents_slice = createSlice({
  name: "incidents",
  initialState: {
    incidents: [],
  },
  reducers: {
    addIncidentsToStore: (state, action) => {
      state.incidents = action.payload;
    },
    addNewIncident: (state, action) => {
      state.incidents.unshift(action.payload);
    },
    updateIncident: (state, action) => {
      state.incidents = state.incidents.map((incident) =>
        incident.id === action.payload.id ? action.payload : incident
      );
    },
    deleteIncidentFromStore: (state, action) => {
      state.incidents = state.incidents.filter(
        (incident) => incident.id !== action.payload.id
      );
    },
  },
});

export const {
  addIncidentsToStore,
  addNewIncident,
  updateIncident,
  deleteIncidentFromStore,
} = incidents_slice.actions;

export default incidents_slice.reducer;
