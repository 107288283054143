import { createSlice } from "@reduxjs/toolkit";

const terminations_slice = createSlice({
  name: "terminations",
  initialState: {
    terminations: [],
    termination: {},
  },
  reducers: {
    addTerminationsToStore: (state, action) => {
      state.terminations = action.payload;
    },
    addOneTerminationToStore: (state, action) => {
      state.terminations.unshift(action.payload);
    },
    addTerminationDetailsToStore: (state, action) => {
      state.termination = action.payload;
    },

    addTerminationToStore: (state, action) => {
      state.termination = action.payload;
    },
  },
});

export const {
  addTerminationsToStore,
  addOneTerminationToStore,
  addTerminationDetailsToStore,
  addTerminationToStore,
} = terminations_slice.actions;

export default terminations_slice.reducer;
