import { createSlice } from "@reduxjs/toolkit";

const organization_slice = createSlice({
  name: "organization",
  initialState: {
    onboarding_steps: [],
    leave_types: [],
    exit_reasons: [],
    documents: [],
    organization_basic: {},
    values: {},
    mission: "",
    vision: "",
  },
  reducers: {
    addOnboardingStepsToStore: (state, action) => {
      state.onboarding_steps = action.payload;
    },
    addExitReasonsToStore: (state, action) => {
      state.exit_reasons = action.payload;
    },
    addOneExitReasonToStore: (state, action) => {
      state.exit_reasons.unshift(action.payload);
    },
    updateOneExitReasonInStore: (state, action) => {
      const exit_reason_index = state.exit_reasons.findIndex(
        (e) => e.id === action.payload.id
      );

      state.exit_reasons[exit_reason_index] = action.payload;
    },
    removeOneExitReasonFromStore: (state, action) => {
      state.exit_reasons = state.exit_reasons.filter(
        (e) => e.id !== action.payload.id
      );
    },
    addLeaveTypesToStore: (state, action) => {
      state.leave_types = action.payload;
    },
    removeOneLeaveTypeFromStore: (state, action) => {
      state.leave_types = state.leave_types.filter(
        (l) => l.id !== action.payload.id
      );
    },
    addDocumentsToStore: (state, action) => {
      state.documents = action.payload;
    },
    removeOneDocumentFromStore: (state, action) => {
      state.documents = state.documents.filter(
        (experience) => experience.id !== action.payload.id
      );
    },
    addOneDocumentToStore: (state, action) => {
      state.documents.unshift(action.payload);
    },
    updateMissionInStore: (state, action) => {
      state.mission = action.payload;
    },
    updateVisionInStore: (state, action) => {
      state.vision = action.payload;
    },
    addAllValuesToStore: (state, action) => {
      state.values = action.payload;
    },
    addValueToStore: (state, action) => {
      state.values = {
        ...action.payload,
        ...state.values,
      };
    },
    editValueInStore: (state, action) => {
      const [key, ...data] = action.payload;
      state.values = {
        ...state.values,
        [key]: data[0],
      };
    },
    removeValueFromStore: (state, action) => {
      const { [action.payload]: value, ...rest } = state.values;
      state.values = rest;
    },
  },
});

export const {
  addOnboardingStepsToStore,
  addLeaveTypesToStore,
  removeOneLeaveTypeFromStore,
  addExitReasonsToStore,
  addOneExitReasonToStore,
  removeOneExitReasonFromStore,
  updateOneExitReasonInStore,
  addDocumentsToStore,
  addOneDocumentToStore,
  removeOneDocumentFromStore,
  updateMissionInStore,
  updateVisionInStore,
  addValueToStore,
  addAllValuesToStore,
  editValueInStore,
  removeValueFromStore,
} = organization_slice.actions;

export default organization_slice.reducer;
