import { createSlice } from "@reduxjs/toolkit";

const announcements_slice = createSlice({
  name: "announcements",
  initialState: {
    announcements: {},
  },
  reducers: {
    addAnnouncementsToStore: (state, action) => {
      state.announcements = action.payload;
    },
    addNewAnnouncement: (state, action) => {
      state.announcements.unshift(action.payload);
    },
    editAnnouncement: (state, action) => {
      state.announcements = state.announcements.map((announcement) =>
        announcement.id === action.payload.id ? action.payload : announcement
      );
    },
    deleteAnnouncement: (state, action) => {
      state.announcements = state.announcements.filter(
        (announcement) => announcement.id !== action.payload.id
      );
    },
  },
});

export const {
  addAnnouncementsToStore,
  addNewAnnouncement,
  editAnnouncement,
  deleteAnnouncement,
} = announcements_slice.actions;

export default announcements_slice.reducer;
