import { createSlice } from "@reduxjs/toolkit";

const outboardings_slice = createSlice({
  name: "outboardings",
  initialState: {
    outboardings: [],
    onboardingHistory: [],
  },
  reducers: {
    addEmployeeOutboardingToStore: (state, action) => {
      const { outboarding, onboarding_history } = action.payload;
      state.outboardings = outboarding;
      state.onboardingHistory = onboarding_history;
    },

    updateEmployeeOutboardingStep: (state, action) => {
      const { step, status, comment } = action.payload;
      const outboardingIndex = state.outboardings.findIndex(
        (o) => o.step === step
      );

      if (outboardingIndex !== -1) {
        state.outboardings[outboardingIndex] = {
          ...state.outboardings[outboardingIndex],
          step,
          status,
          comments: comment,
        };
      }
    },
  },
});

export const { addEmployeeOutboardingToStore, updateEmployeeOutboardingStep } =
  outboardings_slice.actions;

export default outboardings_slice.reducer;
