import { createSlice } from "@reduxjs/toolkit";

const locations_slice = createSlice({
  name: "locationss",
  initialState: {
    locations: [],
    location: {},
  },
  reducers: {
    addLocationsToStore: (state, action) => {
      state.locations = action.payload;
    },
    addOneLocationToStore: (state, action) => {
      state.locations.unshift(action.payload);
    },
    removeOneLocationFromStore: (state, action) => {
      state.locations = state.locations.filter((location) => location.id !== action.payload.id);
    },
  
    updateOneLocationInStore: (state, action) => {
      const updatedLocation = action.payload;
      state.locations = state.locations.map((location) =>
        location.id === updatedLocation.id ? updatedLocation : location
      );
    },
  },
});


export const {
  addLocationsToStore,
  addOneLocationToStore,
  removeOneLocationFromStore,
  updateOneLocationInStore
} = locations_slice.actions;

export default locations_slice.reducer;
