import { createSlice } from "@reduxjs/toolkit";

const profile_slice = createSlice({
  name: "profile",
  initialState: {
    profile: {},
    experiences: [],
    documents: [],
    educations: [],
    next_of_kins: [],
    certificates: [],
    socials: [],
  },
  reducers: {
    addProfileToStore: (state, action) => {
      state.profile = action.payload;
    },
    addProfileDetailsToStore: (state, action) => {
      state.profile = action.payload;
    },
    updateProfileInfo: (state, action) => {
      state.profile = action.payload;
    },
    addExperiencesToStore: (state, action) => {
      state.experiences = action.payload;
    },
    removeOneExperienceFromStore: (state, action) => {
      state.experiences = state.experiences.filter(
        (experience) => experience.id !== action.payload.id
      );
    },
    addOneExperienceToStore: (state, action) => {
      state.experiences.unshift(action.payload);
    },
    updateExperienceInStore: (state, action) => {
      state.experiences = state.experiences.map((experience) =>
        experience.id === action.payload.id ? action.payload : experience
      );
    },
    addEducationDetailsToStore: (state, action) => {
      state.educations = action.payload;
    },
    addOneEducationDetailToStore: (state, action) => {
      state.educations.unshift(action.payload);
    },
    updateEducationDetailInStore: (state, action) => {
      state.educations = state.educations.map((education) =>
        education.id === action.payload.id ? action.payload : education
      );
    },
    deleteEducationDetail: (state, action) => {
      state.educations = state.educations.filter(
        (education) => education.id !== action.payload.id
      );
    },
    addDocumentsToStore: (state, action) => {
      state.documents = action.payload;
    },
    removeOneDocumentFromStore: (state, action) => {
      state.documents = state.documents.filter(
        (experience) => experience.id !== action.payload.id
      );
    },
    addOneDocumentToStore: (state, action) => {
      state.documents.unshift(action.payload);
    },
    addCertificatesToStore: (state, action) => {
      state.certificates = action.payload;
    },
    removeOneCertificateFromStore: (state, action) => {
      state.certificates = state.certificates.filter(
        (document) => document.id !== action.payload.id
      );
    },
    addOneCertificateToStore: (state, action) => {
      state.certificates.unshift(action.payload);
    },
    addKinsToStore: (state, action) => {
      state.next_of_kins = action.payload;
    },
    deleteNextOfKin: (state, action) => {
      state.next_of_kins = state.next_of_kins.filter(
        (next_of_kin) => next_of_kin.id !== action.payload.id
      );
    },
    addOneKinToStore: (state, action) => {
      state.next_of_kins.unshift(action.payload);
    },
    updateKinInStore: (state, action) => {
      state.next_of_kins = state.next_of_kins.map((next_of_kin) =>
        next_of_kin.id === action.payload.id ? action.payload : next_of_kin
      );
    },
    addSocialToStore: (state, action) => {
      state.socials = action.payload;
    },
    removeOneSocialFromStore: (state, action) => {
      state.socials = state.socials.filter(
        (social) => social.id !== action.payload.id
      );
    },
    addOneSocialToStore: (state, action) => {
      state.socials.unshift(action.payload);
    },
    updateSocialInStore: (state, action) => {
      state.socials = state.socials.map((social) =>
        social.id === action.payload.id ? action.payload : social
      );
    },
  },
});

export const {
  addProfileToStore,
  addProfileDetailsToStore,
  updateProfileInfo,
  updateProfileContact,
  addExperiencesToStore,
  removeOneExperienceFromStore,
  addOneExperienceToStore,
  updateExperienceInStore,
  addDocumentsToStore,
  addOneDocumentToStore,
  removeOneDocumentFromStore,
  addCertificatesToStore,
  addOneCertificateToStore,
  removeOneCertificateFromStore,
  addOneNextOfKinToStore,
  deleteNextOfKin,
  updateKinInStore,
  addOneKinToStore,
  addKinsToStore,
  addOneEducationDetailToStore,
  addEducationDetailsToStore,
  updateEducationDetailInStore,
  deleteEducationDetail,
  addSocialToStore,
  removeOneSocialFromStore,
  addOneSocialToStore,
  updateSocialInStore,
} = profile_slice.actions;

export default profile_slice.reducer;
