import { createSlice } from "@reduxjs/toolkit";

const onboardings_slice = createSlice({
  name: "onboardings",
  initialState: {
    onboardings: [],
  },
  reducers: {
    addEmployeeOnboardingToStore: (state, action) => {
      state.onboardings = action.payload;
    },

    updateEmployeeOnboardingStep: (state, action) => {
      const { step, status, comment } = action.payload;
      const onboardingIndex = state.onboardings.findIndex(
        (o) => o.step === step
      );

      if (onboardingIndex !== -1) {
        state.onboardings[onboardingIndex] = {
          ...state.onboardings[onboardingIndex],
          step,
          status,
          comments: comment,
        };
      }
    },
  },
});

export const { addEmployeeOnboardingToStore, updateEmployeeOnboardingStep } =
  onboardings_slice.actions;

export default onboardings_slice.reducer;
