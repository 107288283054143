import { createSlice } from "@reduxjs/toolkit";

const suggestions_slice = createSlice({
  name: "suggestions",
  initialState: {
    suggestions: [],
  },
  reducers: {
    addSuggestionsToStore: (state, action) => {
      state.suggestions = action.payload;
    },
    addOneSuggestionToStore: (state, action) => {
      state.suggestions.unshift(action.payload);
    },
    deleteSuggestionFromStore: (state, action) => {
      state.suggestions = state.suggestions.filter(
        (suggestion) => suggestion.id !== action.payload.id
      );
    },
  },
});

export const {
  addSuggestionsToStore,
  addOneSuggestionToStore,
  deleteSuggestionFromStore,
} = suggestions_slice.actions;

export default suggestions_slice.reducer;
