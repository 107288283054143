import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./slices/profile";
import announcementsReducer from "./slices/announcements";
import employeesReducer from "./slices/employee";
import unitsReducer from "./slices/units";
import locationsReducer from "./slices/location";
import organizationReducer from "./slices/organization";
import onboardingsReducer from "./slices/onboardings";
import outboardingsReducer from "./slices/outboardings";
import authReducer from "./slices/auth";
import incidentsReducer from "./slices/incident";
import positionReducer from "./slices/position";
import feedbacksReducer from "./slices/feedback";
import terminationsReducer from "./slices/terminations";
import exitReasonsReducer from "./slices/exit-reasons";
import gradeLevelsReducer from "./slices/grade-levels";
import suggestionsReducer from "./slices/suggestions";

export const store = configureStore({
  reducer: {
    feedbacks: feedbacksReducer,
    announcements: announcementsReducer,
    auth: authReducer,
    employees: employeesReducer,
    profile: profileReducer,
    organization: organizationReducer,
    onboardings: onboardingsReducer,
    incidents: incidentsReducer,
    outboardings: outboardingsReducer,
    units: unitsReducer,
    locations: locationsReducer,
    positions: positionReducer,
    terminations: terminationsReducer,
    exit_reasons: exitReasonsReducer,
    gradeLevels: gradeLevelsReducer,
    suggestions: suggestionsReducer,
  },
});
